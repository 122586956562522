import React from 'react'
import { Link } from "gatsby"


const Exp = props => (

    <Link to={props.link} target="_blank" class="transform transition duration-500 ease-in-out hover:scale-105 flex flex-col pl-4 pr-4 pt-6 bg-primary shadow-sm border border-stroke border-opacity-4 hover:border-transparent rounded-md">

        <div class="md:max-w-9/12 mx-auto bg-secondary bg-opacity-50 bg-cover bg-center h-5/6 rounded-t-xl"><img src={props.image} class="rounded-t-xl" alt={props.alt} /></div>
        <div><p class="heading text-xl font-semibold text-center mx-4 my-6 md:my-6">{props.title} 
        </p>
        
        </div>

    </Link>


)

export default Exp
