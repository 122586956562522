import React from 'react'
import Card from '../components/card'
import Button from '../components/button'
import MegaCard from '../components/MegaCard'
import Exp from '../components/experiement'
import Layout from '../components/layout';
import SkillsL from '../assets/skills-light-bg.svg'
import SkillsD from '../assets/skills-dark-bg.svg'
import Avatar from '../assets/Avatar.svg'
import Footer from '../components/footer'
import { Link } from 'gatsby'
import Preview from '../components/Preview'



const IndexPage = () => (
  <Layout>

    <Preview />

    <div class="md:w-9/12 container mx-auto pt-12 md:pt-20 pb-4 md:pb-10 px-4">

      <p data-sal="slide-up"
        data-sal-duration="900"
        data-sal-delay="200"
        data-sal-easing="ease" class="heading subpixel-antialiased text-4xl font-semibold text-center text-theme-text md:text-5xl leading-snug md:leading-tight">Product designer.</p>
      <p data-sal="slide-up"
        data-sal-duration="900"
        data-sal-delay="200"
        data-sal-easing="ease" class="heading subpixel-antialiased text-4xl font-semibold text-center text-theme-text md:text-5xl leading-snug md:leading-tight">Solving complex problems </p>
      <p data-sal="slide-up"
        data-sal-duration="900"
        data-sal-delay="200"
        data-sal-easing="ease" class="heading subpixel-antialiased text-4xl font-semibold text-center text-theme-text  md:text-5xl leading-snug md:leading-tight">with design. </p>
      <p data-sal="slide-up"
        data-sal-duration="800"
        data-sal-delay="400"
        data-sal-easing="ease" class="mx-auto md:w-8/12 2xl:w-6/12 subpixel-antialiased pt-4 text-base md:text-xl font-base text-center leading-normal md:leading-normal"> I currently design private equity and compliance management solutions for businesses on Carta. </p>

    </div>

    <img class="object-cover w-full skills-light-bg" src={SkillsL} alt="skills" />
    <img class="object-cover w-full skills-dark-bg" src={SkillsD} alt="skills" />

    <div class="animate-bounce flex justify-center md:-mt-24 px-2 md:px-0 sticky bottom-16 gap-1">

      <img data-sal="zoom-in"
        data-sal-duration="1200"
        data-sal-delay="600"
        data-sal-easing="ease" class="w-16 md:w-auto bg-accent rounded-full" src={Avatar} alt="Avatar" />
      <div class="self-center">
        <div data-sal="zoom-out"
          data-sal-duration="900"
          data-sal-delay="800"
          data-sal-easing="ease" class="bg-background-alt bg-opacity-100 border border-stroke border-opacity-5 rounded-r-2xl rounded-tl-2xl shadow-sm py-2 px-4">
          <p class="text-sm text-left md:text-base md:w-72 secondary-text">Learn more about how I leverage design to solve complex problems 👇</p>
        </div>
      </div>

    </div>

    <div id="case-studies" class="bg-secondary mt-20 bg-opacity-30 py-8 md:py-20 rounded-t-2xl">
      <div class="px-4 md:px-0 md:w-9/12 2xl:w-7/12 mx-auto ">
        <p data-sal="slide-up"
          data-sal-duration="700"
          data-sal-delay="200"
          data-sal-easing="ease" class="heading subpixel-antialiased text-4xl font-semibold  text-left text-theme-text leading-normal md:leading-tight">💼 Work</p>
        <p data-sal="slide-up"
          data-sal-duration="500"
          data-sal-delay="200"
          data-sal-easing="ease" class="md:w-8/12 subpixel-antialiased pt-4 text-base md:text-xl font-base text-left leading-normal md:leading-normal">
          Some interesting projects that I have enjoyed working on.
        </p>
      </div>
      <div data-sal="slide-up"
        data-sal-duration="700"
        data-sal-delay="302"
        data-sal-easing="ease" class="grid md:grid-cols-1 px-4 md:px-0 md:w-9/12 2xl:w-7/12 pt-8 mx-auto">

        <MegaCard class=" " title="Trustpilot reviews on Shopify"
          description="Improving how eCommerce businesses on Shopify collect and showcase Trustpilot reviews. 👜"
          link="/trustpilot-shopify"
          image="https://res.cloudinary.com/dcj89ranh/image/upload/v1668196672/Shopify_Mockup.svg"
          alt="projectCard" />

      </div>
      <div data-sal="slide-up"
        data-sal-duration="700"
        data-sal-delay="302"
        data-sal-easing="ease" class="grid md:grid-cols-2 gap-6 md:gap-12 px-4 md:px-0 md:w-9/12 2xl:w-7/12 py-6 md:py-12 mx-auto">


        <Card class=" " title="TrustScore insights"
          description="Designing actionable insights for businesses to manage their Trustpilot rating. ⭐️ "
          link="/trustscore"
          image="https://res.cloudinary.com/dcj89ranh/image/upload/v1640733493/TrustScore/TrustScore.png"
          alt="projectCard" />


        <Card class=" " title="Quizboot"
          description="Designing a consistent mobile and desktop web experience on a quiz platform. 📚 "
          link="/quizboot"
          image="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610550607/covers/Quizboot.png"
          alt="projectCard" />

        <Card class="" title="Trivago"
          description=" Helping people explore and find the best travel destinations. 🏝"
          link="/trivago"
          image="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1612530528/trivago/Trivago_Card.png"
          alt="projectCard" />

        <Card class="" title="Chrome Mobile"
          description="Redesigning the tab switching experience on Google chrome mobile. 🌍"
          link="/chrome"
          image="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610881321/chrome/Chrome.png"
          alt="projectCard" />






        {/* <Card class="" title="Klasconnect"
          description="Improving the learning experience between students and teachers"
          link="/klasconnect"
          image="https://res.cloudinary.com/dcj89ranh/image/upload/q_auto/v1610550590/covers/Klasconnect.png"
          alt="projectCard" /> */}


      </div>
    </div>

    <div id="about-me" class="mt-4 py-8 md:py-20">
      <div class="px-4 md:px-0 md:w-9/12 2xl:w-7/12 mx-auto">
        <p data-sal="slide-up"
          data-sal-duration="700"
          data-sal-delay="200"
          data-sal-easing="ease" class="heading subpixel-antialiased text-4xl font-semibold text-left text-theme-text leading-normal md:leading-tight">👨🏽‍💻 About me</p>
        <p data-sal="slide-up"
          data-sal-duration="700"
          data-sal-delay="280"
          data-sal-easing="ease" class="md:w-9/12 subpixel-antialiased pt-6 text-base md:text-xl font-base text-left leading-normal md:leading-normal">

          I'm driven by a passion for solving problems using design by creating simple experiences and interfaces that streamline complex processes. </p>


        <p data-sal="slide-up"
          data-sal-duration="900"
          data-sal-delay="302"
          data-sal-easing="ease" class="md:w-9/12 subpixel-antialiased pt-6 pb-8 text-base md:text-xl font-base text-left leading-normal md:leading-normal">

          The concept of growth through continuous learning and practice captivates me. Beginning my journey as a Visual Designer and transitioning into Product Design, I've gained invaluable insights into how design can effectively solve problems and drive positive change.
        </p>


        <Button class="" title=""
          cta="Learn more"
          link="/about"
          alt="button" />


      </div>

    </div>

    <div id="experiments" class="bg-secondary py-8 md:py-20 rounded-t-2xl">
      <div class="px-4 md:px-0 md:w-9/12 2xl:w-7/12  mx-auto">
        <p data-sal="slide-up"
          data-sal-duration="700"
          data-sal-delay="200"
          data-sal-easing="ease" class="heading subpixel-antialiased text-4xl font-semibold  text-left text-theme-text leading-normal md:leading-tight">👨🏽‍🔬 Experiments</p>
        <p ata-sal="slide-up"
          data-sal-duration="900"
          data-sal-delay="300"
          data-sal-easing="ease" class="md:w-9/12 subpixel-antialiased pt-4 text-base md:text-xl font-base text-left leading-normal md:leading-normal">
          A few exciting interactions I have worked on. </p>

      </div>

      <div data-sal="slide-up"
        data-sal-duration="500"
        data-sal-delay="200"
        data-sal-easing="ease" class="grid md:grid-cols-2 gap-6 md:gap-12 px-4 md:px-0 md:w-9/12 2xl:w-7/12 py-8 mx-auto">

        <Exp title="Google Pay Exploration"
          link="https://dribbble.com/shots/13956997-Protopie-5-0-Google-Pay-Exploration"
          image="https://cdn.dribbble.com/users/1786021/screenshots/13956997/media/9975becc7e3d29eebe803ac43d1e58bd.gif"
          alt="projectCard" />


        <Exp title="Spotify Exploration"
          link="https://dribbble.com/shots/12814126-Protopie-5-0-Spotify-exploration"
          image="https://cdn.dribbble.com/users/1786021/screenshots/12814126/media/dd89a88f258f98659b6a766a6da27fb0.gif"
          alt="experimentCard" />




      </div>

    </div>

    <Footer />
    <div class="w-auto md:w-5/12 2xl:w-4/12 mx-auto md:px-4 overflow-hidden sticky bottom-0 md:bottom-8 z-200">
      <div data-sal="slide-up"
        data-sal-duration="400"
        data-sal-delay="1000"
        data-sal-easing="ease" class="flex justify-center sal-animate">
        <Link to="#case-studies" class="group hover:bg-orange-900 transform transition duration-500 ease-in-out flex flex-col md:w-40 w-96 pb-1.5 bg-orange-800 shadow-sm border-2 border-orange-900 hover:border-transparent rounded-lg rounded-r-none">
          <div class="bg-orange-600 bg-opacity-50 rounded-md rounded-r-none border-b-2 border-orange-900 text-plain font-semibold  group-hover:bg-orange-700 "><p class="mx-auto text-center py-2">Work</p></div>

        </Link>
        <Link to="#about-me" class="group hover:bg-orange-900 transform transition duration-500 ease-in-out flex flex-col md:w-40 w-96 pb-1.5 bg-orange-800 shadow-sm border-2 border-l-0 border-r-0 border-orange-900 hover:border-transparent ">
          <div class="bg-orange-600 bg-opacity-50 border-b-2 border-orange-900 text-plain font-semibold  group-hover:bg-orange-700 "><p class="mx-auto text-center py-2">About</p></div>

        </Link>
        <Link to="#experiments" class="group hover:bg-orange-900 transform transition duration-500 ease-in-out flex flex-col md:w-40 w-96 pb-1.5 bg-orange-800 shadow-sm border-2 border-orange-900 hover:border-transparent rounded-lg rounded-l-none">
          <div class="bg-orange-600 bg-opacity-50 rounded-md rounded-l-none border-b-2 border-orange-900 text-plain font-semibold  group-hover:bg-orange-700 "><p class="mx-auto text-center py-2">Experiments</p></div>

        </Link>

      </div>
    </div>




  </Layout>
);
export default IndexPage;


