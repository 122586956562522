import React from 'react'
import { Link } from "gatsby"


const Card = props => (

    <Link to={props.link} class="group hover:bg-orange-900 transform transition duration-500 ease-in-out flex flex-col md:w-40 pb-1.5 bg-orange-800 shadow-sm border-2 border-orange-900 hover:border-transparent rounded-lg">
        <div class="bg-orange-600 bg-opacity-50 rounded-md border-b-2 border-orange-900 text-plain font-semibold group-hover:bg-orange-700"><p class="mx-auto text-center py-2">{props.cta}</p></div>

    </Link>

)

export default Card
