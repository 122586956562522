import React from 'react'
import { Link } from "gatsby"


const Card = props => (

    <div data-sal="slide-up"
        data-sal-duration="400"
        data-sal-delay="300"
        data-sal-easing="ease" class="container mx-auto pt-8 pb-12">


        <div class="flex w-11/12 md:w-3/12 pt-8 pb-5 mx-auto">
            {/* <hr class="border border-divider w-4/12 my-auto" /> */}
            <p class="font-semibold text-main text-sm tracking-wide text-center my-auto mx-auto">👋🏽 You can also find me on</p>
            {/* <hr class="border border-divider w-4/12 my-auto" /> */}

        </div>
        <div class="flex w-11/12 md:w-4/12 mx-auto">
            <Link to="https://dribbble.com/walehg" target="_blank" class="flex-1 font-semibold text-sm md:text-base tracking-wider text-center hover:opacity-80 my-auto" style={{ color: '#EA4C89' }}>Dribbble</Link> 
            <Link to="https://behance.net/walehg" target="_blank" class="flex-1 font-semibold text-sm md:text-base tracking-wider text-center hover:opacity-80 my-auto" style={{ color: '#0057FF' }}>Behance</Link> 
            <Link to="https://www.figma.com/@walehg" target="_blank" class="flex-1 font-semibold text-sm md:text-base tracking-wider text-center hover:opacity-80 my-auto" style={{ color: '#5551ff' }}>Figma</Link> 
            <Link to="https://linkedin.com/in/badarao" target="_blank" class="flex-1 font-semibold text-sm md:text-base tracking-wider text-center hover:opacity-80 my-auto" style={{ color: '#0A66C2' }}>LinkedIn</Link>

        </div>

        <div class="font-semibold text-xs text-gray-400 pt-8">
        ✨ Freshly baked with TailwindCSS & Gatsby JS © {new Date().getFullYear()} 
        </div>

    </div>
)

export default Card
