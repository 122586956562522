import React from 'react'
import { Link } from "gatsby"


const Card = props => (

    <Link to={props.link} class="transform transition duration-500 ease-in-out hover:scale-105 flex flex-col pl-4 pr-4 pt-2 md:pt-6 bg-primary shadow-sm border border-stroke border-opacity-4 hover:border-transparent rounded-md">

        <div><p class="heading mx-auto text-2xl md:text-2xl font-semibold text-center pt-2 md:pt-0">{props.title}</p></div>
        <div><p class="mx-auto text-center py-2 md:w-11/12 md:h-16">{props.description}</p></div>
    

        <div class="mx-auto bg-secondary bg-opacity-50 h-10/12 mt-2 md:mt-4 rounded-t-xl"><img src={props.image} alt={props.alt} /></div>


    </Link>



)

export default Card
