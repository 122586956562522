import React from 'react'
import { Link } from "gatsby"


const MegaCard = props => (

    <Link to={props.link} class="transform transition duration-500 ease-in-out hover:scale-105 hover:border-transparent shadow-sm md:grid grid-cols-2 gap-4 bg-primary border border-stroke border-opacity-4 rounded-md">
        <div class="flex flex-col p-4 md:p-8">

            <div class="flex flex-col">
                <div class="flex-none">
                    <div class="md:flex flex-none">
{/* 
                        <div class="flex text-center">
                            <p class="px-1 py-1 mx-auto md:mx-px mb-2 md:mb-4 rounded-sm text-center text-xs font-bold bg-special-50 text-plain tracking-wider border-orange-400">

                                LATEST PROJECT</p>
                        </div> */}

                        <div class="flex-none">
                           
                        </div>


                    </div>


                </div>

                <div class="flex-none">
                    <p class="heading text-2xl md:text-2xl font-semibold text-center md:text-left">

                        {props.title}</p>
                </div>
            </div>
            <div><p class="mx-auto text-center md:text-left md:h-16 py-2 float-left">{props.description}</p></div>

        </div>


        <div class="bg-opacity-50 rounded-t-xl"><img src={props.image} alt={props.alt} class=" -mt-8 md:mt-0 md:w-11/12 mx-auto md:float-right md:h-auto" /></div>

    </Link>



)

export default MegaCard
